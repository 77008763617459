import App from 'next/app';

import withReduxStore from 'utils/withReduxStore';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import config from 'config';
import Head from 'next/head';

import { loadClipboard } from 'containers/Clipboard/store';
import { withRouter } from 'next/router';
import { GoogleTagManager } from '@next/third-parties/google'
import { google } from 'config'

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

if (IS_PRODUCTION) {
  Sentry.init({
    dsn: 'https://69df8232f860448abbfd1f05f0dbd0da@sentry.fingoweb.com/16',
    release: config.version,
  });
}

class MyApp extends App {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadClipboard()(
      this.props.reduxStore.dispatch,
      this.props.reduxStore.getState
    );
  }

  componentDidCatch(error, errorInfo) {
    if (IS_PRODUCTION) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });

      super.componentDidCatch(error, errorInfo);
    }
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;

    return (
      <Provider store={reduxStore}>
        <Head>
          <meta
            content='minimum-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no'
            name='viewport'
          />
        </Head>
        <Component {...pageProps} />
        {IS_PRODUCTION && <GoogleTagManager gtmId={google.analytics} />}
      </Provider>
    );
  }
}

export default withRouter(withReduxStore(MyApp));
